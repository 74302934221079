<template>
    <div class="order-search">
        <el-form :class="['search-content', `${type}`]"
            v-if="type === 'shopify'"
            label-position="top">
            <el-row :gutter="24">
                <el-col :span="6">
                    <el-form-item label="Order Id">
                        <el-input size="small" v-model="ruleForm.keyword" placeholder="Order Id" clearable></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="6">
                    <el-form-item label="SKU#">
                        <el-input size="small" v-model="ruleForm.sku" placeholder="SKU#" clearable></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="6">
                    <el-form-item label="Order Date">
                        <el-date-picker
                            v-model="ruleForm.dates"
                            type="daterange"
                            size="small"
                            range-separator="-"
                            start-placeholder="From Pick Date"
                            end-placeholder="To Pick Date">
                        </el-date-picker>
                    </el-form-item>
                </el-col>
                <el-col :span="6">
                    <div class="btn-groups">
                        <el-button type="primary" size="small">Filter</el-button>
                        <el-button icon="el-icon-download"
                            :loading="loading"
                            plain
                            size="small"
                            @click="handleExport">
                            Export
                        </el-button>
                    </div>
                </el-col>
            </el-row>
        </el-form>
    </div>
</template>

<script>
export default {
    props: {
        type: String
    },
    data () {
        return {
            ruleForm: {},
            loading: false
        }
    },
    methods: {
        // 导出
        handleExport () {
            this.loading = true
            setTimeout(() => {
                this.loading = false
            }, 3000)
        }
    }
}
</script>

<style lang="scss" scoped>
.order-search {
    padding: 20px 0;
    .search-content.shopify {
        /deep/ .el-input {
            width: 300px;
        }
        /deep/ .el-form-item {
            margin-bottom: 0;
        }
        /deep/ .el-form-item__label {
            margin-bottom: 5px;
            padding-bottom: 0;
            line-height: 25px;
        }
        .btn-groups {
            display: flex;
            align-items: flex-end;
            justify-content: flex-end;
            margin-top: 34px;
        }
        .export-btn {
            margin-left: 10px;
            width: 104px;
            height: 34px;
            border: 1px solid #e6e8eb;
            border-radius: 2px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: $mainPrimary;
            cursor: pointer;
            img{
                margin-right: 5px;
            }
        }
    }
}
</style>
