<template>
    <div class="order-product-info">
        <rap-image class="img"></rap-image>
        <div class="info-wrap">
            <span class="title">{{ data.title }}</span>
            <span class="order-id">Order ID: {{ data.orderId }}</span>
        </div>
    </div>
</template>

<script>
export default {
    props: ['data']
}
</script>

<style lang="scss" scoped>
.order-product-info {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    .img {
        display: inline-block;
        flex-grow: 0;
        flex-shrink: 0;
        width: 60px;
        height: 60px;
        margin-right: 10px;
    }
    .info-wrap {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        .title {
            font-size: 14px;
            font-family: PingFangSC, PingFangSC-Regular;
            font-weight: 400;
            color: #000000;
            line-height: 20px;
            word-break: break-all;
            @include ellipsis(2);
        }
        .order-id {
            margin-top: 5px;
            font-size: 12px;
            font-family: PingFangSC, PingFangSC-Regular;
            font-weight: 400;
            color: #808080;
            line-height: 14px;
            transform: scale(0.83);
            margin-left: -20px;
        }
    }
}
</style>
