<template>
    <div class="shopify-order__card">
        <div class="header-wrap">
            <div class="left">
                Creation Time: {{ data.createTime }}
            </div>
            <div class="right">
                <el-button size="small" plain>Cancel</el-button>
                <el-button type="primary" size="small">Fulfill Order</el-button>
            </div>
        </div>
        <div class="content-wrap">
            <div class="content-item"
                v-for="(col, index) in columns"
                :key="index"
                :style="{
                    'min-width': `${col.minWidth}px`,
                    'text-align': col.align,
                    'flex': col.width ? `0 0 ${col.width}px` : 1,
                }">
                <div v-if="col.prop === 'infomation'" class="cell">
                    <order-product-info :data="data"></order-product-info>
                </div>
                <div v-else class="cell">{{ data[col.prop] }}</div>
            </div>
        </div>
    </div>
</template>

<script>
import OrderProductInfo from '@/views/rap/orders/components/order-product-info.vue'
export default {
    name: 'order-card-item',
    props: {
        data: Object,
        columns: Array
    },
    components: {
        OrderProductInfo
    },
    data () {
        return {}
    }
}
</script>

<style lang="scss" scoped>
.shopify-order__card {
    margin-top: 10px;
    border: 1px solid #dedede;
    overflow: hidden;
    .header-wrap {
        box-sizing: border-box;
        width: 100%;
        height: 48px;
        background: #f5f5f5;
        border-bottom: 1px solid #dedede;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 0 16px;
        .left {
            font-size: 12px;
            font-family: PingFangSC, PingFangSC-Regular;
            font-weight: 400;
            color: #808080;
        }
    }
    .content-wrap {
        padding: 10px 0;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        &:not(:last-of-type) {
            border-right: 1px solid red;
        }
        .content-item {
            flex: 1;
            width: 0px;
            &:not(:last-of-type) {
                border-right: 1px solid red;
            }
            .cell {
                padding: 0 15px;
            }
        }
    }
}
</style>
