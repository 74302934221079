<template>
    <div class="order-tab__wrap">
        <div class="tab-list">
            <div class="tab-item"
                :class="{'active': active === tab.id}"
                v-for="tab in tabs"
                :key="tab.id"
                @click="handleTab(tab)">
                {{ tab.name }}
                <span class="badge" v-if="tab.count">
                    <span class="content">{{ tab.count }}</span>
                </span>
                <span v-if="$slots[tab.id]" class="slot-wrap">
                    <slot :name="tab.id"></slot>
                </span>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        tabs: Array,
        active: [String, Number]
    },
    methods: {
        handleTab (tab) {
            if (this.active !== tab.id) {
                this.$emit('change', tab.id)
                this.$emit('update:active', tab.id)
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.order-tab__wrap {
    position: relative;
    z-index: 90;
    .tab-list {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        .tab-item {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            height: 35px;
            padding: 0 10px 0 15px;
            font-size: 14px;
            font-family: PingFangSC, PingFangSC-Regular;
            font-weight: 400;
            color: #1a1a1a;
            position: relative;
            border-top: 1px solid $borderColor;
            background: white;
            border-top-left-radius: 2px;
            border-bottom: 1px solid $borderColor;
            cursor: pointer;
            &:not(:first-of-type) {
                padding-left: 30px;
            }
            &:first-of-type {
                border-left: 1px solid $borderColor;
            }
            &.active {
                border-color: $mainPrimary;
                background: $mainPrimary;
                color: white;
                &::after {
                    background: $mainPrimary;
                    border-right-color: transparent;
                    border-color: $mainPrimary;
                    transition: all .3s ease-in-out;
                }
                .badge {
                    color: white;
                    background: rgba($color: #ffffff, $alpha: 0.4);
                    transition: all .3s ease-in-out;
                }
                transition: all .3s ease-in-out;
            }
            &::after{
                position: absolute;
                content: ' ';
                display: inline-block;
                right: -10px;
                top: -1px;
                bottom: -1px;
                width: 22px;
                background: white;
                z-index: 1;
                transform: skew(18deg, 0);
                transform-origin: left top;
                border-top-right-radius: 8px;
                border: 1px solid $borderColor;
                border-left: none;
                transition: all .3s ease-in-out;
            }
            transition: all .3s ease-in-out;
            .badge {
                display: flex;
                justify-content: center;
                align-items: center;
                height: 17px;
                min-width: 17px;
                background: rgba($color: #1890ff, $alpha: .1);
                border-radius: 10px;
                font-size: 10px;
                font-family: PingFangSC, PingFangSC-Regular;
                font-weight: 400;
                color: #1890ff;
                margin-left: 10px;
                transition: all .3s ease-in-out;
                box-sizing: border-box;
                padding: 0 5px;
                .content {
                    font-size: 12px;
                    transform: scale(0.83);
                }
            }
            .slot-wrap {
                margin-left: 10px;
            }
        }
    }
}
</style>
