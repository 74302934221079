<template>
    <div class="order-table__list">
        <!-- header -->
        <div class="order-table__header">
            <div class="header-item"
                v-for="(col, index) in columns"
                :key="index"
                :style="{
                    'min-width': `${col.minWidth}px`,
                    'text-align': col.align,
                    'flex': col.width ? `0 0 ${col.width}px` : 1,
                }">
                <div class="cell">{{ col.label }}</div>
            </div>
        </div>
        <!-- 列表 -->
        <div class="order-table__content"
            :style="{
                'max-heigt': maxHeight ? `${maxHeight}px` : 'auto'
            }">
            <div v-for="(item, index) in data" :key="index">
                <slot name="content" :data="{ item, columns }"></slot>
            </div>
            <!-- <template v-if="component">
                <component v-for="(item, index) in data"
                    :key="index"
                    :data="item"
                    :is="component"
                    :columns="columns">
                </component>
            </template> -->
        </div>
    </div>
</template>

<script>
export default {
    props: {
        data: Array,
        columns: Array,
        component: Object,
        maxHeight: Number
    }
}
</script>

<style lang="scss" scoped>
.order-table__list {
    display: flex;
    flex-direction: column;
    .order-table__header {
        background: #f5f5f5;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        border-radius: 10px 10px 0px 0px;
        border: 1px solid #dedede;
        overflow: hidden;
        .header-item {
            // flex: 1;
            flex-grow: 1;
            text-align: left;
            &:not(:last-of-type) {
                border-right: 1px solid red;
            }
            .cell {
                padding: 15px;
                font-size: 14px;
                font-family: PingFangSC, PingFangSC-Medium;
                font-weight: 500;
                color: rgba(51,51,51,0.85);
            }
        }
    }
    .order-table__content {
        overflow: auto;
        min-height: 140px;
    }
}
</style>
