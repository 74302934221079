<template>
    <div class="main-content-wrap paynow-order">
        <!-- 订单状态 -->
        <order-tab :active.sync="active" :tabs="tabs" @change="handleTabChange"></order-tab>
        <!-- 订单内容 -->
        <transition name="el-fade-in">
            <div class="order-content__wrap" v-if="showList">
                <!-- 搜索 -->
                <order-search type="shopify"></order-search>
                <!-- 列表 -->
                <order-table-list :data="dataList"
                    :columns="columns">
                    <template slot="content" slot-scope="{data}">
                        <order-card-item :data="data.item" :columns="data.columns"></order-card-item>
                    </template>
                </order-table-list>
                <!-- 分页 -->
                <rap-pagination class="page-border" :total="total" :currentPage="pageNum" :pageSize="pageSize" @change="pageChange"></rap-pagination>
            </div>
        </transition>
    </div>
</template>

<script>
/* eslint-disable */
import OrderTab from '@/views/rap/components/order-tab/order-tab.vue'
import OrderSearch from '@/views/rap/orders/components/order-search.vue'
import OrderTableList from '@/views/rap/orders/components/order-table.vue'
import OrderCardItem from '@/views/rap/orders/shopify/order-card.vue'

export default {
    components: {
        OrderTab,
        OrderSearch,
        OrderTableList,
        OrderCardItem
    },
    data () {
        return {
            tabs: [
                { id: 'all', name: 'ALL', count: 100 },
                { id: 'paid', name: 'Already paid', count: 22 },
                { id: 'delivered', name: 'To be delivered', count: 20 },
                { id: 'shipped', name: 'Shipped', count: 30 },
                { id: 'cancel', name: 'Canceled', count: 10 }
            ],
            active: 'all',
            columns: [
                { label: 'Product Information', prop: 'infomation', components: '', width: 300 },
                { label: 'Seller', prop: 'customer', components: '' },
                { label: 'Unit Price', prop: 'unitprice', components: '', width: 160 },
                { label: 'Freight', prop: 'freight', components: '' },
                { label: 'Purchase QTY', prop: 'purchase', components: '' },
                { label: 'Grand Total', prop: 'total', components: '' }
            ],
            dataList: [
                {
                    orderId: 'D20200384747',
                    createTime: '2020-11-14 00:16:40',
                    title: 'HI VILLA 5 Piece Metal Outdoor Indoor Dining Chairs and Larg…',
                    customer: 'Michael Lee',
                    total: '$ 38.44',
                    freight: '2.44',
                    unitprice: '111',
                    freight: '$ 38.44',
                    purchase: '10'
                }
            ],
            total: 0,
            pageNum: 1,
            pageSize: 10,
            showList: true
        }
    },
    methods: {
        handleTabChange (val) {
            this.showList = false
            setTimeout(() => {
                this.showList = true
            }, 100)
            this.pageNum = 1
            this.reqDataList()
        },
        // 分页
        pageChange (data) {
            debugger
        },
        // 列表请求数据
        reqDataList () {
            console.log('reqDataList')
        }
    }
}
</script>

<style lang="scss" scoped>
.paynow-order {
    margin-top: 25px;
    margin-bottom: 25px;
    .order-content__wrap {
        padding: 0 15px;
        margin-top: -1px;
        background: white;
        border: 1px solid $borderColor;
    }
    .page-border {
        margin: 0;
    }
}
</style>
